var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page brochure" },
    [
      _vm._l(_vm.sections, function(section, key) {
        return _c("SectionSelector", {
          key: key,
          attrs: {
            data: section.data,
            section: section.type,
            hash: section.hashAnchor,
            variant: section.variant,
            "order-no": section.order_no
          }
        })
      }),
      _c("OrganismMenuRight", {
        attrs: { "phone-header": _vm.phoneNumberHeader }
      }),
      _c("OrganismSidebarCampaign")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }