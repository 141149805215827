<template>
  <div class="page brochure">
    <SectionSelector
      v-for="(section, key) in sections"
      :data="section.data"
      :section="section.type"
      :hash="section.hashAnchor"
      :variant="section.variant"
      :order-no="section.order_no"
      :key="key"
    />
    <OrganismMenuRight :phone-header="phoneNumberHeader" />
    <OrganismSidebarCampaign />
  </div>
</template>

<script>
import SectionSelector from "./SectionSelector";
import OrganismSidebarCampaign from "@/components/organisms/OrganismSidebarCampaign";
import OrganismMenuRight from "../../components/organisms/OrganismMenuRight";
const config = require("../../config").default;

export default {
  name: "BrochurePage",
  metaInfo() {
    return {
      title: this.meta.baseTitle,
      titleTemplate: `%s - ${this.meta.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.meta.description,
        },
      ],
    };
  },
  components: {
    OrganismMenuRight,
    OrganismSidebarCampaign,
    SectionSelector,
  },
  computed: {
    meta() {
      return this.$route.meta.page.meta;
    },
    sectionsData() {
      return this.$route.meta.page.sections;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    phoneNumberHeader() {
      if (!this.generalConfig.phoneNumberHeader) return "";
      return this.generalConfig.phoneNumberHeader;
    },
  },
  data() {
    return {
      sections: [],
    };
  },
  created() {
    this.sections = this.sectionsData.sort((a, b) => {
      if (a.order_no < b.order_no) {
        return -1;
      }
      if (a.order_no > b.order_no) {
        return 1;
      }
      return 0;
    });
  },
  beforeMount() {
    if (this.$store.state.base.documentIsReady) {
      const base = this.$store.getters.constants.base;
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
        false
      );
    }
  },
};
</script>

<style lang="scss">
@import "../../scss/buttons";

.brochure {
  .organism-menu-right {
    .btn-general {
      background: transparent;
      color: $black;
      border: 1px solid $main-color;
      &:hover,
      &.active {
        background: $main-color;
        color: $white;
      }
    }
    .phone-header-parent {
      .phone-header {
        border-color: $main-color-opacity-01;
        .icon {
          color: $black !important;
        }
      }
      &:hover {
        .phone-header {
          .icon {
            color: $white !important;
          }
        }
      }
    }
  }
}
</style>
